import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { mainColor } from '../../constants/colors';
// 最新【你我港钱】blog文章卡片的组件
const BlogCard = ({ post }) => {
  const intl = useIntl();
  const titleStyle = css`
    margin-bottom: 5px;
    width: 100%;
  `;
  const authorDateStyle = css`
    margin-bottom: 5px;
    width: 100%;
  `;
  const topicsStyle = css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  `;
  const tagStyle = css`
    height: 28px;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    font-size: 14px;
    color: ${mainColor};
    background: #f2f2f2;
    margin-right: 10px;
    margin-bottom: 10px;
  `;
  if (!post || !post.blog_id) {
    return (
      <div
        css={css`
          flex: 0 0 100%;
          ${Tablet} {
            flex: 0 0 48%;
          }
          ${Desktop} {
            flex: 0 0 32%;
          }
        `}
      ></div>
    );
  }
  return (
    <a
      css={css`
        cursor: pointer;
        text-align: left;
        margin-bottom: 20px;
        flex: 0 0 100%;
        ${Tablet} {
          flex: 0 0 48%;
          margin-bottom: 10px;
        }
        ${Desktop} {
          flex: 0 0 32%;
        }
      `}
      href={`/${intl.locale}/blog/${post.blog_id}/${post.blog_url}`}
      target='_blank'
    >
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 171.44px; //限定显示图片的高度
          padding-top: 52.5%;
          margin-bottom: 15px;
        `}
      >
        <img
          css={css`
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            border-radius: 10px;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
          `}
          src={`${post.banner_image}?x-oss-process=image/resize,m_fill,w_1456,h_762/quality,Q_70/format,jpg/interlace,1`}
          alt={post.title}
        />
      </div>
      {/* <h3
        css={css`
          font-weight: bold;
          font-size: 20px;
          ${Tablet} {
            font-size: 16px;
          }
          ${Desktop} {
            font-size: 20px;
          }
        `}
      >
        <FormattedHTMLMessage id={post.title} />
      </h3> */}
      <h3 css={titleStyle}>
        <b>{post.title}</b>
      </h3>
      <h5 css={authorDateStyle}>{`${post.authors} / ${post.publish_date}`}</h5>
      <div css={topicsStyle}>
        {[post.topics]
          .filter((tag) => tag)
          .map((tag) => {
            return (
              <div key={tag} css={tagStyle}>
                #{tag.toLowerCase()}
              </div>
            );
          })}
      </div>
    </a>
  );
};

// 更多投资咨询的blog文章卡片组件
const BlogCardForMoreAboutInvesting = ({ blog, post }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      investingBlog1: file(
        relativePath: { eq: "stories/more-about-investing/1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      investingBlog2: file(
        relativePath: { eq: "stories/more-about-investing/2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      investingBlog3: file(
        relativePath: { eq: "stories/more-about-investing/3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  const imgName = intl.formatMessage({ id: `${blog}.img` });
  const imgUrl = data[imgName].childImageSharp.fluid.src;
  return (
    <a
      css={css`
        cursor: pointer;
        flex: 0 0 100%;
        ${Tablet} {
          flex: 0 0 48%;
        }
        ${Desktop} {
          flex: 0 0 32%;
        }
      `}
      onClick={() => {
        // 判断blog用测试环境链接or生产环境链接
        const devOrTestEvnReg = /(^test-)|(^localhost)|(^127)|(^0)/;
        let urlType = '';
        if (devOrTestEvnReg.test(window.location.hostname)) {
          // 测试环境
          urlType = 'test-';
        }
        // 跳转至卡片相应的blog文章
        let blogUrl = intl.formatMessage({
          id: `${blog}.${urlType}url`,
        });
        // window.location.href = `/${intl.locale}${blogUrl}`;
        window.open(`/${intl.locale}${blogUrl}`);
      }}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 171.44px; //限定显示图片的高度
          padding-top: 52.5%;
          margin-bottom: 15px;
        `}
      >
        <img
          css={css`
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            border-radius: 10px;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
          `}
          src={`${imgUrl}?x-oss-process=image/resize,m_fill,w_1456,h_762/quality,Q_70/format,jpg/interlace,1`}
          alt={intl.formatMessage({ id: `${blog}.title` })}
        />
      </div>
      <h3
        css={css`
          font-weight: bold;
          font-size: 20px;
          ${Tablet} {
            font-size: 16px;
          }
          ${Desktop} {
            font-size: 20px;
          }
        `}
      >
        <FormattedHTMLMessage id={`${blog}.title`} />
      </h3>
    </a>
  );
};

export { BlogCard, BlogCardForMoreAboutInvesting };
