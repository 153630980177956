import ActiveApproach from "../../components/IndividualsActiveApproach";
import React from "react";
import { css } from "@emotion/core";
import { Desktop, Tablet } from "../../constants/responsive";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FormattedHTMLMessage, useIntl, Link } from "gatsby-plugin-intl";
import ScrollContainer from "react-indiana-drag-scroll";
import MoveButton from "../../components/IndividualsMoveButton";
import { useWindowSize } from "react-use";
import Arrow from "../../svgs/stories/Arrow.svg";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const StoriesCard = ({ imgName }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      portraitEn1: file(relativePath: { eq: "stories/people/en1.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitEn2: file(relativePath: { eq: "stories/people/en2.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitEn3: file(relativePath: { eq: "stories/people/en3.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitEn4: file(relativePath: { eq: "stories/people/en4.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitEn5: file(relativePath: { eq: "stories/people/en5.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitEn6: file(relativePath: { eq: "stories/people/en6.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitEn7: file(relativePath: { eq: "stories/people/en7.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhCn1: file(relativePath: { eq: "stories/people/zh-cn1.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhCn2: file(relativePath: { eq: "stories/people/zh-cn2.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhCn3: file(relativePath: { eq: "stories/people/zh-cn3.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhCn4: file(relativePath: { eq: "stories/people/zh-cn4.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhCn5: file(relativePath: { eq: "stories/people/zh-cn5.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhCn6: file(relativePath: { eq: "stories/people/zh-cn6.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhCn7: file(relativePath: { eq: "stories/people/zh-cn7.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhHk1: file(relativePath: { eq: "stories/people/zh-hk1.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhHk2: file(relativePath: { eq: "stories/people/zh-hk2.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhHk3: file(relativePath: { eq: "stories/people/zh-hk3.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhHk4: file(relativePath: { eq: "stories/people/zh-hk4.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhHk5: file(relativePath: { eq: "stories/people/zh-hk5.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhHk6: file(relativePath: { eq: "stories/people/zh-hk6.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portraitZhHk7: file(relativePath: { eq: "stories/people/zh-hk7.png" }) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <>
      <div
        css={css`
          background: gray;
          height: 220px;
          width: 220px;
          flex: 0 0 auto;
          margin-right: 10px;
          ${Tablet} {
            height: 210px;
            width: 210px;
            // padding-left: ;
            margin-right: 8.7px;
          }
          ${Desktop} {
            height: 388px;
            width: 388px;
            // padding-left: 35.5px;
          }
        `}
      >
        <Img
          fluid={data[imgName].childImageSharp.fluid}
          imgStyle={{ objectFit: "fill" }}
          loading="eager"
        />
      </div>
    </>
  );
};

const MoveRight = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={className}
      style={{ ...style, transform: "translate(-60px,-60px)" }}
    >
      <Arrow
        css={css`
          ${Tablet} {
            width: 74px;
            height: 74px;
            display: none;
          }
          ${Desktop} {
            width: 89px;
            height: 89px;
            display: block;
          }
        `}
      />
    </div>
  );
};

const MoveLeft = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={className}
      style={{ ...style, zIndex: "100", transform: "translate(-20px,-60px)" }}
    >
      <Arrow
        css={css`
          transform: rotate(180deg);
          ${Tablet} {
            // width: 74px;
            // height: 74px;
            display: none;
          }
          ${Desktop} {
            display: block;
            width: 89px;
            height: 89px;
          }
        `}
      />
    </div>
  );
};

const Carousel = ({ currentIndex = 0, ...externalCss }) => {
  const intl = useIntl();

  const { width: windowWidth } = useWindowSize();
  let clickButton = true;
  /* 手触滑动or点击滑动 */
  if (windowWidth < 768) {
    /* 手机屏 */
    clickButton = false;
  } else {
    /* 电脑屏 平板屏 */
    clickButton = true;
  }

  let showActiveApproachNum = 3;

  let combinationArr = [1, 2, 3, 4, 5, 6, 7];

  return (
    <div {...externalCss}>
      <div
        css={css`
          // display: ${clickButton ? "flex" : "none"};
          position: relative;
          display: none;
          ${Tablet} {
            display: none;
            max-width: 648px;
          }
          ${Desktop} {
            display: flex;
            max-width: 1205px;
          }

          .slick-next:before {
            color: transparent !important;
          }
        `}
      >
        <Slider
          autoplay
          infinite
          autoplaySpeed={10000}
          slidesToShow={showActiveApproachNum}
          slidesToScroll={1}
          nextArrow={<MoveRight />}
          prevArrow={<MoveLeft />}
          css={css`
            width: 100%;
            justify-content: center;

            ${Tablet} {
              // margin-left: 12px;
              .slick-slider {
                // margin-left: 8.7px;
                width: 100%;
              }
            }

            ${Desktop} {
              // margin-left: 0px;
              .slick-track {
                // margin-left: 15px;
              }
            }
          `}
        >
          {combinationArr.map((val) => {
            return (
              <StoriesCard
                key={val}
                imgName={intl.formatMessage({
                  id: `stories.people.portrait-${val}`,
                })}
              />
            );
          })}
        </Slider>
      </div>

      <ScrollContainer
        css={css`
          // display: ${clickButton ? "none" : "flex"};

          justify-content: space-between;
          width: 100%;
          display:flex;
          ${Tablet} {
            display: flex;
          }
          ${Desktop}{
            display: none;
          }
        `}
      >
        {combinationArr.map((val) => {
          return (
            <StoriesCard
              key={val}
              imgName={intl.formatMessage({
                id: `stories.people.portrait-${val}`,
              })}
            />
          );
        })}
      </ScrollContainer>
    </div>
  );
};

export default Carousel;
